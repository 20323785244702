/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"

//Images
import volume_OFF from "../../static/sound-off.svg"

import volume_ON from "../../static/sound-on.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [volume, setVolume] = useState(false)

  const vidRef = useRef(false)

  const toggle_VolumeIcon = () => setVolume(() => !volume)

  const volumeIcon = volume ? volume_ON : volume_OFF

  function isMuted() {
    volume ? (vidRef.current.muted = true) : (vidRef.current.muted = false)
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <video className="backVideo" ref={vidRef} playsInline autoPlay loop muted>
        {typeof window !== "undefined" && window.innerWidth < 1000 ? (
          <>
            <source
              src="https://res.cloudinary.com/affycent/video/upload/v1593513900/Mobile_aqxkqm.webm"
              type="video/webm"
            />
            <source
              src="https://res.cloudinary.com/affycent/video/upload/v1593513897/Mobile_olfs3j.mp4"
              type="video/mp4"
            />
          </>
        ) : (
          <>
            <source
              src="https://res.cloudinary.com/affycent/video/upload/v1593513901/Desktop_b4aka0.webm"
              type="video/webm"
            />
            <source
              src="https://res.cloudinary.com/affycent/video/upload/v1593513883/Desktop_vuhnba.mp4"
              type="video/mp4"
            />
          </>
        )}
      </video>
      <div>
        <main>{children}</main>
        <div className="wrapper">
          <div className="contentBox">
            <p className="launchText">
              Compare forex sites rates and fees. Know who has the best offer.
              Launching soon.
            </p>
            <div className="button_icon">
              <a href="https://africa.skannu.com" className="button">
                Join The Community
              </a>
              <img
                className="volumeIcon"
                src={volumeIcon}
                alt="Volume Icon"
                onClick={() => {
                  toggle_VolumeIcon()
                  isMuted()
                }}
                role="presentation"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
